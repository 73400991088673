jQuery(function () {
    //gestion du widget contact en fonction de la taille écran + les textes à afficher/masquer
    function openWidget(event) {
        $(".widget-contact").addClass("open");
        $(".caller").removeClass("open");
        event.preventDefault();
    }
    function closeWidget(event) {
        $(".widget-contact").removeClass("open");
        $(".caller").addClass("open");
        event.preventDefault();
    }
    jQuery(document).on('click', '[data-contact], a[href*=contact]', openWidget);
    jQuery(document).on('click', '[data-contact-close]', closeWidget);

    // Gestion du formulaire de contact en ajax
    jQuery('.widget-contact [data-confirmation]').on('click', function () {
        let confirmation = jQuery(this);
        confirmation.fadeOut(200, function () {
            confirmation.html('').hide();
        });
    });

    let showMessage = function (message, delay = 2000) {
        let confirmation = jQuery('.widget-contact [data-confirmation]');
        confirmation.html(message).show();
        if (delay === 0) return;
        window.setTimeout(function () {
            confirmation.trigger('click');
        }, delay);
    }


    jQuery(document).on('forminvalid.zf.abide', function () {
        showMessage('Certains champs ne sont pas valides !');
    });

    jQuery(document).on('submit', '.widget-contact form', function (event) {
        event.preventDefault();
        let form = jQuery(this);
        form.find('.js-submit-loader').show();
        if (typeof grecaptcha !== 'undefined' && !grecaptcha.getResponse()) {
            showMessage('Un instant...', 0);
            window.setTimeout(function () {
                if (form.find('.js-submit-loader').is(':visible')) {
                    showMessage('Un problème ? n\'hésitez pas à nous appeler !');
                }
            }, 5000);
            return;
        } else if (typeof grecaptcha === 'undefined') {
            form.find('.js-submit-loader').hide();
            showMessage('Votre captcha n\'est pas reconnu !');
            return;
        }
        $.ajax({
            url: addtpl(form.attr('action'), 'contact'),
            method: form.attr('method'),
            data: form.serialize()
        }).done(function (response) {
            form.find('.js-submit-loader').hide();

            let errors = jQuery(response).find('.alert.callout label');
            if (errors.length > 0) {
                showMessage(errors, 0);
            } else {
                showMessage("Merci ! Votre message a été envoyé.", 0);
                form.find('input:not([type=checkbox]), textarea, select').val('');
            }
        }).fail(function (response) {
            form.find('.js-submit-loader').hide();
            showMessage("Une erreur est survenue, veuillez réessayer.");
        });
    });
});