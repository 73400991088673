jQuery(function () {
    if (!!window.matchMedia('(prefers-reduced-motion: reduce)').matches
        || !Foundation.MediaQuery.atLeast("medium")
        || jQuery('body.index').length == 0
    ) {
        return;
    }
    const controller = new ScrollMagic.Controller({ tweenChanges: true });

    /**
     * 
     * Ouverture/fermeture du widget contact
     * 
    */
    const showContactWidgetOn = (triggerElement) => {
        new ScrollMagic.Scene({
            triggerElement,
            duration: "100%",
            reverse: true,
        }).on('enter', function () {
            $('[data-contact]').first().trigger('click');
        }).on('leave', function () {
            $('[data-contact-close]').first().trigger('click');
        }).addTo(controller);
    };
    showContactWidgetOn("#section2");
    showContactWidgetOn("#section9");

    /**
     * 
     * Animation parallax des backgrounds
     * 
    */
    function createParallaxScene(selector, trigger) {
        const parallaxElement = $(selector);
        const parallaxDistance = "-500%";

        const parallaxTween = gsap.fromTo(
            parallaxElement,
            { backgroundPosition: "0% 0%" },
            { backgroundPosition: `0% ${parallaxDistance}`, ease: "none" }
        );

        return new ScrollMagic.Scene({
            triggerElement: trigger,
            duration: "200%",
            triggerHook: 0,
            reverse: true,
        })
            .setTween(parallaxTween)
            .addTo(controller);
    }
    createParallaxScene(".header", "body");
    createParallaxScene("#section6", "#section5");
    createParallaxScene("#section9", "#section8");


    /**
     * 
     * Animation du H1
     * 
    */
    /*
    const headline = $("h1");
 
    const headlineTween = gsap.to(headline, {
        duration: 1,
        y: 200,
        color: "rgb(74, 43, 209)",
        fontSize: "12rem",
        opacity: 0,
        ease: "power2.inOut",
    });

 
    new ScrollMagic.Scene({         
        triggerElement: "body", 
        triggerHook: 0, 
        duration: "100%", 
    })
        .setTween(headlineTween) 
        .addTo(controller);
*/


    /**
     * 
     * Animations des textes section 2
     * 
    */
    // Parcourir tous les spans
    $("#animated-text span").each(function (index, span) {
        // Créer une timeline GSAP
        var timeline = gsap.timeline();

        // Ajouter une animation à la timeline
        timeline.to(span, {
            color: "#4A2BD1", // Couleur finale
            delay: index * 0.2, // Ajouter un délai de 0.2s entre chaque span
            ease: "power1.in", // Type d'animation
        });

        // Créer une scène ScrollMagic et l'ajouter au controller
        new ScrollMagic.Scene({
            triggerElement: "#section2", // Déclencher l'animation lorsque le span est visible
            triggerHook: 0,
            reverse: true, // L'animation ne se déroulera qu'une fois et ne reviendra pas à la couleur d'origine
        })
            .setTween(timeline) // Utiliser la timeline GSAP pour l'animation
            .addTo(controller); // Ajouter la scène au controller ScrollMagic
    });



    /**
     * Animation des éléments ".animate" dans les sections. Ils arrivent par la gauche
     * @param {*} sectionId 
     * @param {*} controller 
     */
    function animateSection(sectionId, controller) {
        // Créer une Timeline GSAP
        const timeline = gsap.timeline();

        timeline.from(`${sectionId} .animate`, {
            duration: 0.5,
            x: -200,
            opacity: 0,
            ease: "power2.inOut",
        });

        new ScrollMagic.Scene({
            triggerElement: sectionId,
            triggerHook: 0.8,
            //reverse: true,
            offset: 150,
        })
            .setTween(timeline)
            //.addIndicators({ name: `section ${sectionId}-H2` })
            .addTo(controller);
    }

    animateSection("#section2", controller);
    animateSection("#section3", controller);
    animateSection("#section4", controller);
    animateSection("#section5", controller);
    animateSection("#section6", controller);
    animateSection("#section7", controller);
    animateSection("#section8", controller);
    animateSection("#section9", controller);

    /**
     * Animation des nombres en bas de page
     */
    new ScrollMagic.Scene({
        triggerElement: "#section9",
        triggerHook: 0.1,
    })
        .on("enter", function () {
            $("svg.radial-progress").each(function (index, value) {
                // Animation des cercles
                $("circle.complete").css("stroke-dashoffset", 360);
                gsap.to($("circle.complete"), {
                    duration: 3,
                    ease: "power1.inOut",
                    strokeDashoffset: 0
                })
                // Animation des nombres
                let targetElement = $(this).find(".target");
                let targetValue = targetElement.text();
                let unit = '';
                if (targetValue.indexOf('%') !== -1) unit = '%';
                else if (targetValue.indexOf('°') !== -1) unit = '°';
                let obj = { value: 0 };
                gsap.to(obj, {
                    value: parseInt(targetValue),
                    duration: 2,
                    ease: "power1.inOut",
                    onUpdate: () => {
                        targetElement.text(Math.round(obj.value) + unit);
                    },
                });
            });
        })
        .addTo(controller);



});

/*DOC

1- méthode ScrollMagic.Scene()
OBJET DE CONFIG : 

triggerElement (string / object / null): Cet élément déclenche l'animation lorsqu'il atteint une certaine position dans la fenêtre de défilement. Vous pouvez spécifier soit un sélecteur CSS, soit un objet DOM.

triggerHook (number / string): Détermine le point de déclenchement de la scène en fonction de la position de la fenêtre de défilement. La valeur peut être un nombre entre 0 et 1 ou une chaîne de caractères. 0 signifie le haut de la fenêtre de défilement, 0.5 le milieu et 1 le bas. Les chaînes de caractères acceptables sont "onEnter", "onCenter" et "onLeave".

duration (number / string / function): Définit la durée de la scène. Elle peut être définie en pixels (nombre) ou en pourcentage de la hauteur de la fenêtre de défilement (chaîne de caractères). Si une fonction est fournie, la fonction est appelée chaque fois que la durée doit être recalculée.

offset (number): Décale le point de déclenchement de la scène de cette quantité de pixels.

reverse (boolean): Détermine si la scène doit se déclencher lors du défilement vers l'arrière. Par défaut, c'est vrai.

loglevel (number): Définit un niveau de journalisation pour cette scène. Le journalisation est désactivée par défaut.

tweenChanges (boolean): Optimise les modifications de position pour les tweens. Utilisé en conjonction avec TweenMax ou TweenLite.

*/
