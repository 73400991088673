import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

jQuery(function () {
    const isReduced = !!window.matchMedia('(prefers-reduced-motion: reduce)').matches

    jQuery(".upslider").slick({
        infinite: true,
        slidesToShow: 1,
        autoplay: !isReduced,
        slidesToScroll: 1,
        speed: isReduced ? 0 : 2500,
        cssEase: "ease-in-out",
    });
    jQuery(".bottomslider").slick({
        infinite: true,
        slidesToShow: 3,
        autoplay: false,
        centerMode: true,
        //variableWidth: true,
        //speed: 3000,
        cssEase: "ease",
        slidesToScroll: 3,

        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    slidesToScroll: 1,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    });

    jQuery(".voonooslider").slick({
        infinite: false,
        slidesToShow: 1,
        autoplay: false,
        arrows: false,
        speed: 500,
        dots: true,
        centerMode: true,
        slidesToScroll: 1,
    });

    jQuery(".footerslider").slick({
        infinite: true,
        slidesToShow: 3,
        autoplay: false,
        cssEase: "ease",
        slidesToScroll: 3,
        rows:0,

        responsive: [
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              
              }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    });


});