jQuery(document).ready(function () {

    // Remove svg.radial-progress .complete inline styling
    $("svg.radial-progress").each(function (index, value) {
        $(this).find($("circle.complete")).removeAttr("style");
    });



    if (!Foundation.MediaQuery.atLeast('medium')) {
        $(".toggle-text-container").each(function () {
            let container = $(this);
            let firstP = container.find("h2").next("p");
            let otherP = firstP.nextAll("p");
            otherP.hide();

            container.on("click", ".toggle-text", function () {
                if (otherP.first().is(":hidden")) {
                    otherP.slideDown();
                    $(this).text("-");
                } else {
                    otherP.slideUp();
                    $(this).text("+");
                }
            });
        });

        //hide txt on section 6
        let tohide = $(".text-to-hide");

        function changetxt() {
            if (tohide.is(":hidden")) {
                $(".toggle-text-b").text("+");
            } else {
                $(".toggle-text-b").text("-");
            }
        }

        $(tohide).hide();

        $(".toggle-text-b").on("click", function () {
            $(tohide).slideToggle();
            changetxt();
        });
    }

    //Menu button on click event
    $(".mobile-nav-button").on("click", function () {
        // Toggles a class on the menu button to transform the burger menu into a cross
        $(
            ".mobile-nav-button .mobile-nav-button__line:nth-of-type(1)"
        ).toggleClass("mobile-nav-button__line--1");
        $(
            ".mobile-nav-button .mobile-nav-button__line:nth-of-type(2)"
        ).toggleClass("mobile-nav-button__line--2");
        $(
            ".mobile-nav-button .mobile-nav-button__line:nth-of-type(3)"
        ).toggleClass("mobile-nav-button__line--3");

        // Toggles a class that slides the menu into view on the screen
        $(".mobile-menu").toggleClass("mobile-menu--open");
        return false;
    });



    $(".faqplus").on("click", function (event) {
        event.preventDefault();
        $(".faq__accordeon .more").slideDown();
        $(".faqplus").remove();
    });

    const btnScrollToTop = document.querySelector(".btnScrollToTop");
    btnScrollToTop.addEventListener("click", function () {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });
    window.addEventListener("scroll", () => {
        if (window.pageYOffset > 100) {
            btnScrollToTop.classList.add("active");
        } else {
            btnScrollToTop.classList.remove("active");
        }
    });


});
