import $ from 'jquery';

global.$ = $;
global.jQuery = $;

import './foundation';
import './app';
import './site';
import './slick';
import './scrollmagic';
import './momentumscroll';
import './widget-contact';

//import './gsapplugin'
//import './accordeon';
